import { useScrollTrigger } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import { Link } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import { complexTransition, menuMediaQuery } from "../../../theme/globalStyles"
import Logo from "../../logo"
import ContactLink from "./contactLink"
import FavIcon from "./favIcon"
import Menu from "./menu"
import MobileMenu from "./menu/mobileMenu"

const Wrapper = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${menuMediaQuery(css`
    flex-direction: row;
    justify-content: space-between;
    padding: ${({ theme }) => `0 ${theme.spacing(2)}px`};
    padding-left: ${({ theme }) => theme.spacing(1)}px;
  `)}
`
interface ScrollTrigger {
  trigger: boolean
}
const LogoWrapper = styled.div<ScrollTrigger>`
  width: ${({ trigger }) => (trigger ? 150 : 75)}px;
  padding: ${({ trigger, theme }) =>
    `${theme.spacing(trigger ? 4 : 2)}px 0 ${theme.spacing(trigger ? 1 : 0)}px`};
  ${complexTransition};

  ${({ trigger, theme }) =>
    menuMediaQuery(css`
      flex-direction: row;
      justify-content: space-between;
      padding: ${theme.spacing(trigger ? 2 : 0.75)}px ${theme.spacing(trigger ? 1 : 0)}px;
    `)}
`

const Spacer = styled.div<ScrollTrigger>`
  min-height: ${({ trigger }) => (trigger ? 229 : 140)}px;
  ${complexTransition};

  ${({ trigger, theme }) =>
    menuMediaQuery(css`
      min-height: ${trigger ? 148 : 77}px;
    `)}
`

const Header: React.FC = () => {
  const trigger = useScrollTrigger({
    threshold: 25,
    disableHysteresis: true,
  })

  return (
    <>
      <Wrapper elevation={!trigger ? 0 : 3}>
        <LogoWrapper trigger={!trigger}>
          <Link to="/">
            <Logo />
          </Link>
        </LogoWrapper>
        <FavIcon />
        <ContactLink />
        <Menu />
        <MobileMenu />
      </Wrapper>
      <Spacer trigger={!trigger} />
    </>
  )
}

export default Header
