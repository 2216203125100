import Link from "@material-ui/core/Link"
import React from "react"
import styled, { css } from "styled-components"
import { createPhoneLink, formatDisplayPhone, whatsappURL } from "../../../helpers"
import useContactInfo from "../../../hooks/useContactInfo"
import { menuMediaQuery } from "../../../theme/globalStyles"
import BounceDown from "../../base/bounceDown"

const Wrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(3.25)}px;
  left: ${({ theme }) => theme.spacing(3)}px;

  a {
    display: inline-block;
    width: 100%;
    font-weight: 700;
    font-size: 110%;
  }

  ${menuMediaQuery(css`
    display: none;
  `)}
`

const ContactLink: React.FC = () => {
  const { telefoonnummer, email } = { ...(useContactInfo() || {}) }

  return (
    <Wrapper>
      <BounceDown>
        <Link color="secondary" align="left" href={`mailto:${email}`}>
          {email}
        </Link>
      </BounceDown>
      {telefoonnummer && (
        <BounceDown>
          <Link color="secondary" align="left" href={createPhoneLink(telefoonnummer)}>
            {formatDisplayPhone(telefoonnummer)}
          </Link>
        </BounceDown>
      )}
      <BounceDown>
        <Link color="secondary" align="left" href={whatsappURL}>
          WhatsApp
        </Link>
      </BounceDown>
    </Wrapper>
  )
}

export default ContactLink
