import Button, { ButtonProps } from "@material-ui/core/Button"
import Fade from "@material-ui/core/Fade"
import MuiMenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import { Tree } from "@valu/trees"
import React, { forwardRef, useEffect, useRef } from "react"
import styled from "styled-components"
import { getLinkProps } from "../../../../helpers"
import useBoolState from "../../../../hooks/useBoolState"
import { MainMenuQuery_wpMenu_menuItems_nodes } from "../../../../hooks/__generated__/MainMenuQuery"
import { useTemplateType } from "../../context"

interface IProps {
  item: Tree<MainMenuQuery_wpMenu_menuItems_nodes>
}

type MenuItemType = React.FC<IProps>

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.palette.tertiary.contrastText};
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px`};
  font-size: 130%;
  text-transform: lowercase;
  border-radius: 0;
  :hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`
const StyledButtonWithRef = forwardRef<HTMLInputElement, ButtonProps>((props, ref) => (
  <StyledButton {...props} innerRef={ref} />
))

const SubMenu = styled(Paper)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 0;

  > ul {
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      div,
      a {
        margin: ${({ theme }) => theme.spacing(0.75)}px;
      }
    }
  }
`

const StyledFade = styled(Fade)`
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
  width: 100%;
`

export const SubMenuList = styled(MenuList)`
  padding: 0;
`

export const SubMenuItem = styled(MuiMenuItem)`
  font-size: 120%;
  padding: ${({ theme }) => theme.spacing(2)}px;
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

const ButtonWrapper = styled.div`
  position: relative;
`
interface ITriangle {
  data_hidden: boolean
}
const Triangle = styled.div<ITriangle>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  width: 0;
  height: 0;

  transition: all 0.225s ease-in-out;

  border-left: ${({ theme }) => theme.spacing(1)}px solid transparent;
  border-right: ${({ theme }) => theme.spacing(1)}px solid transparent;
  border-bottom: ${({ theme, data_hidden }) => theme.spacing(data_hidden ? 0 : 1.5)}px solid
    ${({ theme }) => theme.palette.primary.main};
`

export const Square = styled.div`
  width: ${({ theme }) => theme.spacing(1)}px;
  height: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
  align-self: center;
  margin: 0 ${({ theme }) => theme.spacing(0.5)}px;
`

const activeMainLinkStyle = { activeStyle: { fontWeight: 900 } }
const activeSubLinkStyle = { activeStyle: { fontWeight: 700 } }

const SingleMenuItem: MenuItemType = ({ item }) => (
  <StyledButton {...getLinkProps(item.node.path, activeMainLinkStyle)}>
    {item.node.label}
  </StyledButton>
)

const MultipleMenuItem: MenuItemType = ({ item }) => {
  const [open, { handleOpen, handleClose, toggle }] = useBoolState()
  const ref = useRef(null)
  const scrolled = useScrollTrigger({ threshold: 5 })
  const trigger = useScrollTrigger({
    threshold: 75,
    disableHysteresis: true,
  })

  useEffect(() => {
    handleClose()
  }, [scrolled])

  return (
    <>
      <ButtonWrapper>
        <StyledButtonWithRef
          {...getLinkProps(item.node.path, activeMainLinkStyle)}
          ref={ref}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          onClick={toggle}
        >
          {item.node.label}
        </StyledButtonWithRef>

        <Triangle data_hidden={!open} />
      </ButtonWrapper>

      <StyledFade in={open}>
        <SubMenu elevation={!trigger ? 0 : 3} onMouseEnter={handleOpen} onMouseLeave={handleClose}>
          <SubMenuList>
            {item.children.map((child, i) => [
              <SubMenuItem
                key={child.node.id}
                {...getLinkProps(child.node.path, activeSubLinkStyle)}
              >
                {child.node.label}
              </SubMenuItem>,
              i < item.children.length - 1 && <Square key={`sub-squre-${i}`} />,
            ])}
          </SubMenuList>
        </SubMenu>
      </StyledFade>
    </>
  )
}

const MenuItem: MenuItemType = ({ item }) => {
  const [templateType] = useTemplateType()

  if (templateType === "collection") {
    return <SingleMenuItem item={item} />
  }

  return item.children.length > 0 ? (
    <MultipleMenuItem item={item} />
  ) : (
    <SingleMenuItem item={item} />
  )
}

export default MenuItem
