import { graphql, useStaticQuery } from "gatsby"
import { ContactInfoQueryQuery } from "../__generated__/graphql-gatsby"

export default () => {
  const data = useStaticQuery<ContactInfoQueryQuery>(graphql`
    query ContactInfoQuery {
      wp {
        contactInfo {
          straat
          huisnummer
          postcode
          plaats
          email
          telefoonnummer
          facebook
          instagram
          linkedin
          pinterest
          twitter
          youtube
        }
      }
    }
  `)

  return data.wp?.contactInfo
}
