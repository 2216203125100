import Container from "@material-ui/core/Container"
import React from "react"
import { TemplateTypeProvider } from "./context"
import Footer from "./footer"
import Header from "./header"

interface IProps {}

const Layout: React.FC<IProps> = ({ children }) => {
  return (
    <TemplateTypeProvider>
      <Container
        maxWidth="xl"
        disableGutters
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Header />
        {children}
        <Footer />
      </Container>
    </TemplateTypeProvider>
  )
}

export default Layout
