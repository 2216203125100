import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { LogoQueryQuery } from "../__generated__/graphql-gatsby"

export type LogoProps = {
  className?: string | undefined
}
export type ILogo = React.FC<LogoProps>

const Logo: ILogo = ({ className }) => {
  const query = useStaticQuery<LogoQueryQuery>(graphql`
    query LogoQuery {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, placeholder: NONE)
        }
      }
    }
  `)
  const { gatsbyImageData } = { ...query.file?.childImageSharp }

  return <GatsbyImage image={gatsbyImageData} alt="logo" className={className} />
}

export default Logo
