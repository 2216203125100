import { alpha, useTheme } from "@material-ui/core/styles"
import {
  createGlobalStyle,
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components"

export const complexTransition = css`
  transition: all ${({ theme }) => theme.transitions.duration.complex}ms ease-in-out;
`

export const simpleTransition = css`
  transition: all ${({ theme }) => theme.transitions.duration.standard}ms ease-in-out;
`

export const shadow = css`
  box-shadow: 9px 9px 0 0 rgb(0 0 0 / 30%);
`

export const largeShadow = css`
  box-shadow: 16px 16px 0 0 rgb(0 0 0 / 30%); ;
`

export type CSSString = FlattenInterpolation<ThemeProps<DefaultTheme>>

export type MediaQuery = (cssString: CSSString, flip?: boolean) => CSSString

export const xxlMediaQuery: MediaQuery = (cssString, flip) =>
  mediaQuery(
    css`
      ${({ theme }) => theme.breakpoints.values.xxl}
    `,
    cssString,
    flip
  )

export const xlMediaQuery: MediaQuery = (cssString, flip) =>
  mediaQuery(
    css`
      ${({ theme }) => theme.breakpoints.values.customXl}
    `,
    cssString,
    flip
  )

export const lgMediaQuery: MediaQuery = (cssString, flip) =>
  mediaQuery(
    css`
      ${({ theme }) => theme.breakpoints.values.lg}
    `,
    cssString,
    flip
  )

export const mdMediaQuery: MediaQuery = (cssString, flip) =>
  mediaQuery(
    css`
      ${({ theme }) => theme.breakpoints.values.customMd}
    `,
    cssString,
    flip
  )

export const smMediaQuery: MediaQuery = (cssString, flip) =>
  mediaQuery(
    css`
      ${({ theme }) => theme.breakpoints.values.md}
    `,
    cssString,
    flip
  )

export const xsMediaQuery: MediaQuery = (cssString, flip) =>
  mediaQuery(
    css`
      ${({ theme }) => theme.breakpoints.values.sm}
    `,
    cssString,
    flip
  )

export const xxsMediaQuery: MediaQuery = (cssString, flip) =>
  mediaQuery(
    css`
      ${({ theme }) => theme.breakpoints.values.xxs}
    `,
    cssString,
    flip
  )

export const menuMediaQuery: MediaQuery = (cssString, flip) =>
  mediaQuery(
    css`
      ${({ theme }) => theme.breakpoints.values.menu}
    `,
    cssString,
    flip
  )

const mediaQuery = (breakpoint: CSSString, cssString: CSSString, flip?: boolean) =>
  flip
    ? css`
        @media (min-width: ${breakpoint}px) {
          ${cssString};
        }
      `
    : css`
        @media (max-width: ${breakpoint}px) {
          ${cssString};
        }
      `

export const colorFromCms = (color: Maybe<string>, fadeOut?: number) => {
  const theme = useTheme()

  switch (color) {
    case "blue":
      return typeof fadeOut !== "undefined"
        ? alpha(theme.palette.primary.light, fadeOut)
        : theme.palette.primary.light
    case "dark-blue":
      return typeof fadeOut !== "undefined"
        ? alpha(theme.palette.primary.main, fadeOut)
        : theme.palette.primary.main
    case "yellow":
      return typeof fadeOut !== "undefined"
        ? alpha(theme.palette.tertiary.main, fadeOut)
        : theme.palette.tertiary.main
    default:
      return typeof fadeOut !== "undefined"
        ? alpha(theme.palette.background.default, fadeOut)
        : theme.palette.background.default
  }
}

export const switchBgColor = (bgColor: Maybe<string>) => css`
  background-color: ${colorFromCms(bgColor)};
`

export const GlobalStyle = createGlobalStyle`
  body {
    min-height: 100vh;
    #___gatsby {
    min-height: 100vh;
      #gatsby-focus-wrapper {
        min-height: 100vh;
      }
    }
  }

  ul {
    li {
      list-style: square;
      margin-left: ${({ theme }) => theme.spacing(1.5)}px;
      margin-top: ${({ theme }) => theme.spacing(1.5)}px;
      margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
      ::marker {
        color: ${({ theme }) => theme.palette.secondary.main};
      }
    }
  }
`
