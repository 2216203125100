import IconButton from "@material-ui/core/IconButton"
import Link, { LinkProps } from "@material-ui/core/Link"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Modal from "@material-ui/core/Modal"
import Slide from "@material-ui/core/Slide"
import CloseIcon from "@material-ui/icons/Close"
import MenuIcon from "@material-ui/icons/Menu"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { animated } from "react-spring"
import styled, { css } from "styled-components"
import { createPhoneLink, formatDisplayPhone, whatsappURL } from "../../../../helpers"
import useBoolState from "../../../../hooks/useBoolState"
import useBoop from "../../../../hooks/useBoop"
import useContactInfo from "../../../../hooks/useContactInfo"
import useMainMenu from "../../../../hooks/useMainMenu"
import { menuMediaQuery } from "../../../../theme/globalStyles"
import BounceDown from "../../../base/bounceDown"
import MDDDLink from "../../../base/mdddLink"
import Logo from "../../../logo"

const MenuButton = styled(IconButton)`
  margin-left: auto;
  font-size: ${({ theme }) => theme.spacing(6)}px;

  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    display: inline-flex;
  }
`

const MobileMenuWrapper = styled.div`
  display: block;
  ${menuMediaQuery(
    css`
      display: none;
    `,
    true
  )};
`

const CloseButton = styled(IconButton)`
  font-size: ${({ theme }) => theme.spacing(6)}px;
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)}px;
  right: ${({ theme }) => theme.spacing(1)}px;
  z-index: 10;
`

const LogoWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2.5)}px;
  left: ${({ theme }) => theme.spacing(2.5)}px;
  width: 75px;
  z-index: 10;
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: 0;
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  display: flex;
  flex-direction: column;
`

const InnerWrapper = styled.div`
  position: absolute;
  top: 102px;
  left: 0;
  right: 0;
  bottom: 62px;
  display: flex;
  flex-direction: column;
  outline: 0;
  background-color: inherit;
  overflow-y: scroll;

  box-shadow: inset 0px 3px 3px -2px rgb(0 0 0 / 20%), inset 0px 3px 4px 0px rgb(0 0 0 / 14%),
    inset 0px 1px 8px 0px rgb(0 0 0 / 12%), inset 0px -3px 3px -2px rgb(0 0 0 / 18%),
    inset 0px -3px 4px 0px rgb(0 0 0 / 12%), inset 0px -1px 8px 0px rgb(0 0 0 / 10%);

  padding-top: ${({ theme }) => theme.spacing(2)}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    overflow-x: hidden;
  }
`

const MenuWrapper = styled(MenuList)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: ${({ theme }) => `0 ${theme.spacing(6)}px ${theme.spacing(1)}px`};
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding-left: 0;
    padding-bottom: 0;
  }
  li {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const MenuBTN = styled(props => <MenuItem component={GatsbyLink} {...props} />)`
  padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2)}px`};
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 115%;
  font-weight: 800;
  letter-spacing: 0.1rem;
  justify-content: flex-start;
`

const ChildBTN = styled(MenuBTN)`
  font-weight: 500;
  padding-left: ${({ theme }) => `${theme.spacing(8)}px`};
`

const Footer = styled.div`
  margin-top: auto;
  width: 100%;
`
const Contact = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1.25)}px;
`
type AProps = {
  top: number
} & LinkProps

const A = styled(Link)<AProps>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: 126px;
  z-index: 10;
  font-weight: 700;
  font-size: 120%;
`

const AnimMenuItem: React.FC = ({ children }) => {
  const [style, trigger] = useBoop({ x: 4 })

  return (
    <animated.li style={style} onMouseEnter={trigger}>
      {children}
    </animated.li>
  )
}

const MobileMenu: React.FC = () => {
  const menu = useMainMenu()
  const { telefoonnummer } = { ...(useContactInfo() || {}) }
  const [open, { handleOpen, handleClose }] = useBoolState()
  const [styleOpen, triggerOpen] = useBoop({ y: 4 })
  const [styleClose, triggerClose] = useBoop({ y: 4 })

  return (
    <MobileMenuWrapper>
      <animated.div style={styleOpen}>
        <MenuButton
          color="secondary"
          edge="end"
          children={<MenuIcon fontSize="inherit" />}
          onClick={handleOpen}
          aria-label="open-menu"
          onMouseEnter={triggerOpen}
        />
      </animated.div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="open-menu"
        aria-describedby="mobile-menu"
      >
        <Slide in={open}>
          <Wrapper>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            {telefoonnummer && (
              <BounceDown>
                <A top={28} color="secondary" href={createPhoneLink(telefoonnummer)}>
                  {formatDisplayPhone(telefoonnummer)}
                </A>
              </BounceDown>
            )}
            <BounceDown>
              <A top={56} color="secondary" align="left" href={whatsappURL}>
                WhatsApp
              </A>
            </BounceDown>
            <animated.div style={styleClose}>
              <CloseButton
                aria-label="close-menu"
                color="secondary"
                onClick={handleClose}
                children={<CloseIcon fontSize="inherit" />}
                onMouseEnter={triggerClose}
              />
            </animated.div>

            <InnerWrapper>
              <MenuWrapper>
                {menu?.map(menuItem => {
                  const hasChildren = menuItem?.children.length > 0
                  const childNodes = menuItem?.children
                  const close = menuItem?.node.path === "#" ? () => null : handleClose

                  return [
                    <AnimMenuItem>
                      <MenuBTN key={menuItem?.node.id} onClick={close} to={menuItem?.node.path}>
                        {String(menuItem?.node.label)}
                      </MenuBTN>
                    </AnimMenuItem>,
                    hasChildren
                      ? childNodes?.map((childItem, i) => {
                          const length = Number(menuItem?.children?.length) - 1
                          const end = length === i

                          return [
                            <AnimMenuItem>
                              <ChildBTN
                                key={childItem?.node.id}
                                onClick={handleClose}
                                to={childItem?.node.path}
                              >
                                {String(childItem?.node.label)}
                              </ChildBTN>
                            </AnimMenuItem>,
                            end ? <div style={{ minHeight: 12 }} /> : null,
                          ]
                        })
                      : null,
                  ]
                })}
              </MenuWrapper>
            </InnerWrapper>
            <Footer>
              <Contact>
                <MDDDLink />
              </Contact>
            </Footer>
          </Wrapper>
        </Slide>
      </Modal>
    </MobileMenuWrapper>
  )
}

export default MobileMenu
