import { Consumer, Provider, useContext } from "react"

interface Context<T> {
  Provider: Provider<T>
  Consumer: Consumer<T>
}

export default function useCheckUndefinedContext<C>(
  context: Context<C | undefined>
): C {
  const ctx = useContext(context)
  if (ctx === undefined) throw new Error("Expected Context to be set")
  return ctx
}
