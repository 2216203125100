import { createMuiTheme } from "@material-ui/core"
import { useTheme as useDefaultTheme } from "@material-ui/core/styles"

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    tertiary: Palette["primary"]
  }

  interface PaletteOptions {
    tertiary: PaletteOptions["primary"]
  }
}

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    menu: true
    xxs: true
    customMd: true
    customXl: true
    xxl: true
  }
}

const useTheme = () => {
  const defaultTheme = useDefaultTheme()
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: "#DDFAFF",
        main: "#328DA9",
      },
      secondary: {
        main: "#6F2179",
      },
      tertiary: {
        main: "#FFEF02",
        dark: "#ffa800",
        contrastText: "#000000",
      },
      background: {
        default: "#FFFFFF",
      },
    },
    typography: {
      fontFamily: `"Open Sans"`,
      h1: {
        fontSize: 36,
        lineHeight: 1.75,
        fontWeight: "bold",
      },
      h2: {
        fontSize: 26,
        lineHeight: 1.75,
        fontWeight: "bold",
      },
      h3: {
        fontSize: 22,
        lineHeight: 1.75,
        fontWeight: "bold",
      },
      h4: {
        fontSize: 20,
        lineHeight: 1.75,
        fontWeight: "bold",
      },
      button: {
        textTransform: "none",
        fontSize: 18,
      },
    },
    breakpoints: {
      values: {
        ...defaultTheme.breakpoints.values,
        xxs: 360,
        sm: 680,
        md: 920,
        customMd: 1024,
        menu: 1250,
        customXl: 1366,
        xxl: 1480,
      },
    },
  })

  return theme
}

export default useTheme
