export enum ACTIONS {
  ADD = "ADD",
  REMOVE = "REMOVE",
  INCREMENT = "INCREMENT",
  DECREMENT = "DECREMENT",
}

export type FavoriteObject = {
  id: string
  amount: number
  name: string
  price: number
}

export type FavoriteState = {
  favorites: FavoriteObject[]
  amount: number
}

export type Actions =
  | {
      type: ACTIONS.ADD
      id: string
      name: string
      price: number
    }
  | {
      type: ACTIONS.REMOVE
      id: string
    }
  | {
      type: ACTIONS.INCREMENT | ACTIONS.DECREMENT
      id: string
      amount?: number
    }

export type Reducer = (state: FavoriteState, actions: Actions) => FavoriteState

const reducer: Reducer = (state, actions) => {
  switch (actions.type) {
    case ACTIONS.ADD:
      // if already exists return state as-is
      if (state.favorites.map(f => f.id).includes(actions.id)) {
        return { ...state }
      }

      return {
        favorites: state.favorites.concat({
          id: actions.id,
          name: actions.name,
          price: actions.price,
          amount: 1,
        }),
        amount: state.amount + 1,
      }
    case ACTIONS.REMOVE:
      return {
        favorites: state.favorites.filter(f => f.id !== actions.id),
        amount: state.amount - 1,
      }
    case ACTIONS.INCREMENT:
    case ACTIONS.DECREMENT:
      const increment = actions.type === ACTIONS.INCREMENT
      const amount = actions.amount || 1

      return {
        ...state,
        favorites: state.favorites.map(f =>
          f.id !== actions.id
            ? { ...f }
            : {
                ...f,
                amount: increment ? f.amount + amount : f.amount - amount,
              }
        ),
      }
    default:
      return {
        ...state,
      }
  }
}

export default reducer
