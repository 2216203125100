import React from "react"
import { animated } from "react-spring"
import useSmallBoopDown from "../../../hooks/useSmallBoopDown"

const BounceDown: React.FC = ({ children }) => {
  const [style, trigger] = useSmallBoopDown()

  return (
    <animated.div style={style} onMouseEnter={trigger}>
      {children}
    </animated.div>
  )
}

export default BounceDown
