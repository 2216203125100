import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

interface IProps extends IColumn {
  header: string
}

interface IColumn {
  section: "ONE" | "TWO" | "THREE" | "FOUR"
}
const StyledColumn = styled.section<IColumn>`
  grid-area: ${({ section }) => section};

  height: ${({ theme }) => theme.spacing(18)}px;
  border-bottom: 1px dotted ${({ theme }) => theme.palette.tertiary.contrastText};
`

const Column: React.FC<IProps> = ({ header, section, children }) => {
  return (
    <StyledColumn section={section}>
      <Typography variant="body1" style={{ fontWeight: 600 }} gutterBottom>
        {header}
      </Typography>
      <Typography variant="body2" component="div">
        {children}
      </Typography>
    </StyledColumn>
  )
}

export default Column
