import React, { createContext, useEffect, useReducer } from "react"
import useCheckUndefinedContext from "../../hooks/useCheckUndefinedContext"
import reducer, { ACTIONS, Actions, FavoriteState } from "./reducer"

interface IFavContext {
  favorites: FavoriteState
  dispatch: React.Dispatch<Actions>
  ACTIONS: typeof ACTIONS
}

const Context = createContext<IFavContext | undefined>(undefined)

export const useFavContext = () => useCheckUndefinedContext(Context)

const storageKey = "pup-favorieten"

const storedItem = typeof window !== "undefined" && window.localStorage.getItem(storageKey)

const init: FavoriteState = storedItem ? JSON.parse(storedItem) : { favorites: [], amount: 0 }

const FavContext: React.FC = ({ children }) => {
  const [favorites, dispatch] = useReducer(reducer, init)

  useEffect(() => {
    window.localStorage.setItem(storageKey, JSON.stringify(favorites))
  }, [favorites])

  return <Context.Provider value={{ favorites, dispatch, ACTIONS }}>{children}</Context.Provider>
}

export default FavContext
