import React from "react"
import ThemeWrapper from "../theme/themeWrapper"
import FavContext from "./context/favContext"
import Layout from "./layout"

const WrapRoot: React.FC = ({ children }) => (
  <ThemeWrapper>
    <FavContext>
      <Layout>{children}</Layout>
    </FavContext>
  </ThemeWrapper>
)

export default WrapRoot
