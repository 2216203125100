import React from "react"
import styled, { css } from "styled-components"
import useMainMenu from "../../../../hooks/useMainMenu"
import { menuMediaQuery } from "../../../../theme/globalStyles"
import MenuItem from "./menuItem"

const MenuWrapper = styled.nav`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  ${menuMediaQuery(css`
    display: none;
  `)}
`

const Square = styled.div`
  width: ${({ theme }) => theme.spacing(1)}px;
  height: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.tertiary.contrastText};
  align-self: center;
  margin: 0 ${({ theme }) => theme.spacing(0.5)}px;
  transform: translateY(${({ theme }) => theme.spacing(0.35)}px);
`

const Menu: React.FC = () => {
  const menu = useMainMenu()

  return (
    <MenuWrapper>
      {menu.map((item, i) => [
        <MenuItem key={item.node.id} item={item} />,
        i < menu.length - 1 && <Square key={`square-${i}`} />,
      ])}
    </MenuWrapper>
  )
}

export default Menu
