import Link from "@material-ui/core/Link"
import React from "react"
import styled, { css } from "styled-components"
import { createPhoneLink, formatDisplayPhone, googleMapsLink } from "../../../helpers"
import useContactInfo from "../../../hooks/useContactInfo"
import { smMediaQuery, xxsMediaQuery } from "../../../theme/globalStyles"
import BounceDown from "../../base/bounceDown"
import MDDDLink from "../../base/mdddLink"
import Spacer from "../../base/spacer"
import Column from "./column"
import Socials from "./socials"

const Wrapper = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  color: ${({ theme }) => theme.palette.tertiary.contrastText};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
`

const Grid = styled.footer`
  width: 100%;
  max-width: 1360px;
  padding: ${({ theme }) => theme.spacing(4)}px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: "ONE TWO THREE FOUR";
  grid-gap: ${({ theme }) => theme.spacing(6)}px;

  ${smMediaQuery(css`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: "ONE TWO" "THREE FOUR";
    grid-gap: ${({ theme }) => theme.spacing(3)}px;
  `)}
  ${xxsMediaQuery(css`
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas: "ONE" "TWO" "THREE" "FOUR";
    grid-gap: ${({ theme }) => theme.spacing(2)}px;
  `)}
`

const Footer: React.FC = () => {
  const contactInfo = useContactInfo()

  const { straat, huisnummer, postcode, plaats, telefoonnummer, email } = { ...contactInfo }

  const salesPhone = "0636582265"
  const salesEmail = "info@popuppallets.nl"

  return (
    <Wrapper>
      <Grid>
        <Column section="ONE" header="adres">
          {straat && huisnummer && postcode && plaats && (
            <BounceDown>
              <Link
                color="inherit"
                href={googleMapsLink(straat, huisnummer, postcode, plaats)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${straat} ${huisnummer}`}
                <br />
                {` ${postcode} ${plaats}`}
              </Link>
            </BounceDown>
          )}
        </Column>
        <Column section="TWO" header="contact">
          {telefoonnummer && (
            <BounceDown>
              <Link color="inherit" href={createPhoneLink(telefoonnummer)}>
                {formatDisplayPhone(telefoonnummer)}
              </Link>
            </BounceDown>
          )}

          <BounceDown>
            <Link color="inherit" href={`mailto:${email}`}>
              {email}
            </Link>
          </BounceDown>
        </Column>
        <Column section="THREE" header="verkoop/informatie">
          <BounceDown>
            <Link color="inherit" href={createPhoneLink(salesPhone)}>
              {formatDisplayPhone(salesPhone, true)}
            </Link>
          </BounceDown>

          <BounceDown>
            <Link color="inherit" href={`mailto:${salesEmail}`}>
              {salesEmail}
            </Link>
          </BounceDown>
        </Column>
        <Column section="FOUR" header="social media">
          <Socials />
        </Column>
      </Grid>
      <Spacer height={18} />
      <MDDDLink />
      <Spacer height={12} />
    </Wrapper>
  )
}

export default Footer
