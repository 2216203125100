import Badge from "@material-ui/core/Badge"
import IconButton from "@material-ui/core/IconButton"
import FavoriteIcon from "@material-ui/icons/Favorite"
import { Link } from "gatsby"
import React, { useEffect } from "react"
import { animated } from "react-spring"
import styled, { css } from "styled-components"
import useBoop from "../../../hooks/useBoop"
import { menuMediaQuery } from "../../../theme/globalStyles"
import BounceDown from "../../base/bounceDown"
import { useFavContext } from "../../context/favContext"

const Wrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)}px;
  right: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.palette.tertiary.contrastText};

  ${menuMediaQuery(css`
    position: relative;
    margin-left: auto;
    margin-top: -24.5px;
  `)}

  .badge {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    background-color: ${({ theme }) => theme.palette.secondary.main};
    top: ${({ theme }) => theme.spacing(0.5)}px;
    right: ${({ theme }) => theme.spacing(0.2)}px;
  }
`

const FavIcon: React.FC = () => {
  const {
    favorites: { amount },
  } = useFavContext()

  const [style, trigger] = useBoop({
    scale: 1.33,
    timing: 200,
    springConfig: {
      tension: 300,
      friction: 4,
    },
  })

  useEffect(() => {
    if (amount > 0) {
      trigger()
    }
  }, [amount])

  return (
    <Wrapper>
      <BounceDown>
        <animated.div style={style}>
          <IconButton component={Link} to="/favorieten/" color="inherit">
            <Badge badgeContent={amount} classes={{ badge: "badge" }}>
              <FavoriteIcon fontSize="large" />
            </Badge>
          </IconButton>
        </animated.div>
      </BounceDown>
    </Wrapper>
  )
}

export default FavIcon
