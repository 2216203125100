import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react"

export type Templates = "page" | "product" | "news" | "collection"

const TemplateContext = createContext<[Templates, Dispatch<SetStateAction<Templates>>]>([
  "page",
  () => null,
])

export const useTemplateType = () => useContext(TemplateContext)

export const TemplateTypeProvider: React.FC = ({ children }) => {
  const templateState = useState<Templates>("page")

  return <TemplateContext.Provider value={templateState}>{children}</TemplateContext.Provider>
}
