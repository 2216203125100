import { CssBaseline } from "@material-ui/core"
import React from "react"
import { GlobalStyle } from "./globalStyles"
import Theme from "./theme"

const ThemeWrapper: React.FC = ({ children }) => (
  <Theme>
    <CssBaseline />
    <GlobalStyle />
    {children}
  </Theme>
)

export default ThemeWrapper
