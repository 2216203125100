import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled, { css } from "styled-components"
import { xsMediaQuery } from "../../../theme/globalStyles"
import BounceDown from "../bounceDown"

interface Props {
  color?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error"
}

const LeftAlign = styled(Grid)`
  ${xsMediaQuery(
    css`
      text-align: left;
      padding-left: ${({ theme }) => theme.spacing(1)}px;
    `,
    true
  )}
`
const RightAlign = styled(Grid)`
  ${xsMediaQuery(
    css`
      text-align: right;
      padding-right: ${({ theme }) => theme.spacing(1)}px;
    `,
    true
  )}
`

const MDDDLink: React.FC<Props> = ({ color = "secondary" }) => (
  <Typography
    variant="subtitle1"
    component="div"
    style={{ fontWeight: 600, fontSize: ".95rem", lineHeight: 1.4, width: "100%" }}
    align="center"
  >
    <Grid container>
      <RightAlign item xs={12} sm={6}>
        <BounceDown>
          <Link
            href="https://www.social-media-support.nl"
            rel="author noopener"
            target="_blank"
            color={color}
          >
            Text by Pride & Proud PR
          </Link>
        </BounceDown>
      </RightAlign>
      <LeftAlign item xs={12} sm={6}>
        <BounceDown>
          <Link href="https://www.mddd.nl" rel="author noopener" target="_blank" color={color}>
            Built by MDDD © {new Date().getFullYear()}
          </Link>
        </BounceDown>
      </LeftAlign>
    </Grid>
  </Typography>
)

export default MDDDLink
