import styled from "styled-components"

interface SpacerProps {
  height?: number
}

const Spacer = styled.div<SpacerProps>`
  height: ${({ height, theme }) => (height ? height : theme.spacing(4))}px;
`

export default Spacer
