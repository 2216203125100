// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-favorieten-index-tsx": () => import("./../../../src/pages/favorieten/index.tsx" /* webpackChunkName: "component---src-pages-favorieten-index-tsx" */),
  "component---src-pages-favorieten-offerte-tsx": () => import("./../../../src/pages/favorieten/offerte.tsx" /* webpackChunkName: "component---src-pages-favorieten-offerte-tsx" */),
  "component---src-pages-nieuws-tsx": () => import("./../../../src/pages/nieuws.tsx" /* webpackChunkName: "component---src-pages-nieuws-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-collectie-tsx": () => import("./../../../src/templates/collectie.tsx" /* webpackChunkName: "component---src-templates-collectie-tsx" */),
  "component---src-templates-nieuws-tsx": () => import("./../../../src/templates/nieuws.tsx" /* webpackChunkName: "component---src-templates-nieuws-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

