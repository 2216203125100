import { flatListToTrees } from "@valu/trees"
import { Link } from "gatsby"
import { PageBuilder_pageBuilder_WpPage_PageBuilder_PageBuilder_ContactBanner } from "./templates/__generated__/PageBuilder"

interface BaseTreeType {
  id: any
  parentId: any
}
export type TreeNodes<T extends BaseTreeType> = Maybe<Array<Maybe<T>>>
export const toTrees = <T extends BaseTreeType>(nodes: TreeNodes<T>) =>
  nodes
    ? flatListToTrees(nodes, {
        getId: item => item?.id,
        getParentId: item => item?.parentId,
      })
    : []

export function getLinkProps(URL: string | null, append: any = {}) {
  const url = String(URL)
  const isExternal = url.startsWith("http")

  return {
    component: isExternal ? "a" : Link,
    ...(isExternal ? { href: url } : { to: url }),
    ...append,
  }
}

export const isServer = () => typeof window === "undefined"
export const isClient = () => !isServer()

export function shuffleArray<T>(array: T[]): T[] {
  const copy: T[] = [...array]

  for (let i = copy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[copy[i], copy[j]] = [copy[j], copy[i]]
  }

  return copy
}

export const MuiKeys = ["xs", "sm", "md", "lg", "xl"]

export function isMuiKey(key: Maybe<string>): boolean {
  if (typeof key !== "string") return false

  return MuiKeys.includes(key)
}

export function createPhoneLink(tel: string): string {
  let number = tel
  if (tel.charAt(0) === "0") {
    number = `+31${tel.substring(1)}`
  }

  return `tel:${number}`
}

export function formatDisplayPhone(tel: string, mobile?: boolean): string {
  const regularExp = /\(?(\d{3})\)?[- ]?(\d{4})[- ]?(\d{3})/g
  const mobileExp = /\(?(\d{2})\)?[- ]?(\d{3})[- ]?(\d{3})[- ]?(\d{2})/g
  const regularSubst = "$1 - $2 $3"
  const mobileSubst = "$1 - $2 $3 $4"

  return tel.replace(mobile ? mobileExp : regularExp, mobile ? mobileSubst : regularSubst)
}

export function googleMapsLink(street: string, number: string, zip: string, place: string): string {
  const regex = /\(?(\d{4})\)?([A-Za-z]{2})/g
  const formatZip = zip.replace(regex, "$1+$2")

  return `https://www.google.nl/maps/place/${street}+${number},+${formatZip}+${place}`
}

export const fakeBlock: PageBuilder_pageBuilder_WpPage_PageBuilder_PageBuilder_ContactBanner = {
  __typename: "WpPage_PageBuilder_PageBuilder_ContactBanner",
  hasAltTekst: false,
  text: "",
}

export const whatsappURL = `https://wa.me/31636582265`

type Shuffle = <T>(array: T[]) => T[]

export const shuffle: Shuffle = array => {
  const copy = [...array]
  let currentIndex = copy.length

  while (currentIndex != 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[copy[currentIndex], copy[randomIndex]] = [copy[randomIndex], copy[currentIndex]]
  }

  return copy
}
